<template>
  <div class="">
    <!-- Header -->
    <div class="header py-5">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-dark">
                <img src="img/logo.png" height="100px"/>
              </h1>
              <p class="text-lead text-dark">Digite o usuário ou e-mail de cadastro e recupere sua senha.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(doLogin)">
                  <base-input alternative
                              class="mb-3"
                              name="usuário/email"
                              :rules="{required: true, min: 3}"
                              prepend-icon="ni ni-email-83"
                              placeholder="Digite seu usuário ou e-mail"
                              v-model="form.email">
                  </base-input>

                  <vue-recaptcha
                    ref="recaptcha"
                    :loadRecaptchaScript="true"
                    badge="bottomleft"
                    size="invisible"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    sitekey="6LcL_7kZAAAAAKvH8isNe9m69iPxtOKkTVovNcrX">
                  </vue-recaptcha>

                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4" :disabled="!showSubmit" @click.prevent="recover">Recuperar Senha</base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/login" class="text-primary"><small>Voltar para o login</small></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {failWs, postWs, signin} from '../../ws.service'

  export default {
    components: {
      VueRecaptcha
    },
    data() {
      return {
        showSubmit: true,
        form: {
          email: ''
        },
        validations: {
          email: {
            required: true,
            min: 3,
            ok: false
          }
        }
      };
    },
    methods: {
      recover() {
        const isValid = this.$refs.formValidator.validate();
        if (isValid) {
          this.showSubmit = false
          this.$refs.recaptcha.execute()
        }
      },
      onCaptchaVerified(recaptchaToken) {
        postWs("/auth/forgot-password", false, null, {key: this.form.email, recaptchaToken: recaptchaToken},
          this.successRecover, this.failRecover)
      },
      successRecover() {
        this.$swal({
          title: "Enviado",
          text: "Foi enviado para seu e-mail o procedimento para recuperação de senha. Obrigado!",
          buttonsStyling: true,
          closeOnClickModal: false,
          confirmButtonClass: 'btn btn-success btn-fill',
          icon: "success"
        })
        this.$router.push('/login')
      },
      failRecover(error) {
        if (error.response.status == 404) {
          this.successRecover()
        } else {
          failWs(error)
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      onCaptchaExpired() {
        this.$refs.recaptcha.reset()
      }
    },
    beforeDestroy() {
      this.closeMenu()
    }
  };
</script>
